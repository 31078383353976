import { useState } from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { Text } from '@mentimeter/ragnar-ui/text';
import { getResetsAtText } from '../../lib/hooks/get-resets-at-text';
import { LimitModalProgressBar } from '../progress-bar/LimitModalProgressBar';
import { useHasSeenBefore } from './useHasSeenBefore';

export function ReachedLimitModal({
  progress,
  limit,
  resetsAt,
  trackClickUpgrade,
  trackClickContinue,
  trackDismiss,
}: {
  progress: number;
  limit: number;
  resetsAt: Date;
  trackClickUpgrade(): void;
  trackClickContinue(): void;
  trackDismiss(): void;
}) {
  const { hasSeenBefore } = useHasSeenBefore('reached-limit-modal');
  const [open, setOpen] = useState(!hasSeenBefore);

  const extraProgress = Math.max(0, progress - limit);
  const resetsAtText = getResetsAtText(resetsAt);

  if (hasSeenBefore) return null;

  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      <ModalContainer>
        <ModalHeader />
        <ModalBody
          pt="space6"
          pl="space12"
          pr="space12"
          pb="space12"
          alignItems="center"
        >
          <Text fontSize="175" textAlign="center" color="text">
            Great work!
          </Text>
          {extraProgress > 1 ? (
            <Text mt="space4" color="text" textAlign="center">
              You engaged with{' '}
              <Text as="span" fontWeight="bold">
                {progress}
              </Text>{' '}
              participants this month — the extra {extraProgress} people are on
              us. Upgrade for unlimited participants, or wait until your
              participation limit resets {resetsAtText}.
            </Text>
          ) : (
            <Text mt="space4" color="text" textAlign="center">
              You engaged with{' '}
              <Text as="span" fontWeight="bold">
                {progress}
              </Text>{' '}
              participants this month. Upgrade for unlimited participants, or
              wait until your participation limit resets {resetsAtText}.
            </Text>
          )}
          <LimitModalProgressBar limit={limit} progress={progress} />
        </ModalBody>
        <ModalFooter gap="space1.5">
          <Button ml="space8" variant="secondary" onClick={onClickContinue}>
            Continue on free
          </Button>
          <Button
            variant="positive"
            href="/plans"
            target="_blank"
            onClick={trackClickUpgrade}
          >
            Upgrade
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );

  function onOpenChange(newOpen: boolean) {
    setOpen(newOpen);

    if (!newOpen) {
      trackDismiss();
    }
  }

  function onClickContinue() {
    setOpen(false);
    trackClickContinue();
  }
}
