import { useSearchParams } from '@mentimeter/next-navigation';
import React from 'react';
import { Banner } from '@mentimeter/ragnar-ui/banner';

export const RequestToJoinBannerDataHandler = () => {
  const requestToJoinBanner = useSearchParams().get('request_to_join_banner');
  const [showBanner, setShowBanner] = React.useState(!!requestToJoinBanner);

  if (!showBanner) {
    return null;
  }

  return (
    <Banner
      title={`You have a pending workspace request. Once approved, we'll notify you via email.`}
      data-testid="request-to-join-banner"
      onDismiss={() => {
        setShowBanner(false);
      }}
      theme="info"
      mb="space4"
    />
  );
};
