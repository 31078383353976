'use client';
import dynamic from 'next/dynamic';
import React from 'react';
import { RequestToJoinBannerDataHandler } from 'features/manage-members/banners/RequestToJoinBannerDataHandler';
import { RoleChangeBanner } from 'features/manage-members/banners/RoleChangeBanner';
import { useUser } from '@mentimeter/user';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';

const Notifications = dynamic(() => import('../notifications'), {
  ssr: false,
});
const HomeUserActionsRow = dynamic(() => import('./HomeUserActionsRow'), {
  ssr: false,
  loading: PlaceHolderUserMenu,
});

export function HomeHeader({
  pad = true,
  noPadding = false,
}: {
  pad?: boolean;
  noPadding?: boolean | undefined;
}) {
  const { user, update } = useUser();
  return (
    <Box
      pt={noPadding ? 0 : 4}
      pb={noPadding ? 0 : [1, 4]}
      px={pad && !noPadding ? [3, 4] : 0}
      width="100%"
      bg="transparent"
    >
      <Notifications mb={2} mt={2} />
      <RequestToJoinBannerDataHandler />
      <RoleChangeBanner
        roleChanged={user?.flags?.role_changed}
        previousRole={user?.flags?.previous_role}
        currentRole={user?.role}
        handleRoleChangeDismiss={
          update
            ? () =>
                update({ flags: { role_changed: false } }, 'Role change banner')
            : null
        }
      />
      <HomeUserActionsRow />
    </Box>
  );
}

export function PlaceHolderUserMenu() {
  return (
    <Box
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      height={56}
    >
      <Skeleton variant="rectangle" height="40px" width={[180, 330, 500]} />
      <Skeleton variant="rectangle" height="40px" width={180} />
    </Box>
  );
}
