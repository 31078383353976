import { animated, type SpringValues } from 'react-spring';
import React from 'react';
import { UsersSolidIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { useProgressBarAnimation } from '../use-progress-bar-animation';
import { getResetsAtText } from '../../lib/hooks/get-resets-at-text';

export function LimitModalProgressBar({
  progress,
  limit,
  resetsAt,
}: {
  progress: number;
  limit: number;
  resetsAt?: Date;
}) {
  const { style, progressByLimit } = useProgressBarAnimation(progress, limit);

  return (
    <Box
      width="100%"
      maxWidth={454}
      alignItems="stretch"
      bg="infoWeakest"
      p="space4"
      borderRadius="2xl"
      mt="space8"
    >
      <Box alignItems="center" mb="space2">
        <Text color="onInfoWeakest" fontSize="87.5">
          You have presented to{' '}
          <Text fontWeight="bold" color="onInfoWeakest" fontSize="87.5">
            {progress}/{limit}
          </Text>{' '}
          participants this month.
        </Text>
        {resetsAt && (
          <Text color="onInfoWeakest" fontSize="87.5" mt="space0.5">
            This resets {getResetsAtText(resetsAt)}.
          </Text>
        )}
      </Box>
      <Box flexDirection="row" width="100%" gap="space2" alignItems="center">
        <UsersSolidIcon size={14} color="info" />
        <Box height="8px" position="relative" flex="1 1 auto">
          <Box
            extend={({ theme }) => ({
              background: theme.colors.infoWeak,
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              borderRadius: `${theme.radii[1]}px`,
            })}
          />
          <Box
            data-progress={progressByLimit}
            extend={({ theme }) => ({
              background: theme.colors.info,
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              borderRadius: `${theme.radii[1]}px`,
            })}
            as={animated.div}
            style={style as SpringValues}
          />
        </Box>
      </Box>
    </Box>
  );
}
