import { useExperiment } from '@mentimeter/splitio';
import {
  EngagementStatus,
  EngagementStatusNames,
  useEngagementLimits,
} from '@mentimeter/paywalls-data-hooks';
import {
  contextFromPathname,
  type TrackingContext,
} from '@mentimeter/http-clients';
import { useState } from 'react';
import { trackUser } from '@api/tracking/client';
import { usePathname } from '@mentimeter/next-navigation';
import type { UpgradeEvent } from '../../types';
import { ReachedLimitModal } from './ReachedLimitModal';
import { ActivatedLimitModal } from './ActivatedLimitModal';

enum ModalAction {
  GotIt = 'Got it',
  Continue = 'Continue',
  Dismiss = 'Dismiss',
  Upgrade = 'Upgrade',
}

const SHOW_ACTIVATED_MODAL_THRESHOLD = 1;

export function EngagementLimitModal({
  upgradeEvent,
}: {
  upgradeEvent: UpgradeEvent;
}) {
  const { isInExperiment: isInNewLimitModalExperiment } = useExperiment(
    'PUG_New_Limit_Experience',
    ['modal'],
  );
  const engagementLimits = useEngagementLimits();
  const pathname = usePathname();
  const trackingContext = contextFromPathname(pathname);

  const showModal = isInNewLimitModalExperiment && !!engagementLimits;

  if (!showModal) return null;

  const {
    engagementData: { limit, progress, resetsAt },
    engagementStatus,
  } = engagementLimits;

  return (
    <EngagementLimitModalInner
      upgradeEvent={upgradeEvent}
      trackingContext={trackingContext}
      limit={limit}
      progress={progress}
      resetsAt={resetsAt}
      engagementStatus={engagementStatus}
    />
  );
}
export function EngagementLimitModalInner({
  upgradeEvent,
  trackingContext,
  limit,
  progress,
  resetsAt,
  engagementStatus,
}: {
  upgradeEvent: UpgradeEvent;
  trackingContext: TrackingContext | undefined;
  limit: number;
  progress: number;
  resetsAt: Date;
  engagementStatus: EngagementStatus;
}) {
  const [modalOpened] = useState(Date.now());

  if (engagementStatus === EngagementStatus.ReachedLimit) {
    return (
      <ReachedLimitModal
        progress={progress}
        limit={limit}
        resetsAt={resetsAt}
        trackClickUpgrade={trackClickUpgrade}
        trackClickContinue={trackClickContinue}
        trackDismiss={trackDismiss}
      />
    );
  }
  if (progress >= SHOW_ACTIVATED_MODAL_THRESHOLD) {
    return (
      <ActivatedLimitModal
        progress={progress}
        limit={limit}
        resetsAt={resetsAt}
        trackClickUpgrade={trackClickUpgrade}
        trackClickGotIt={trackClickGotIt}
        trackDismiss={trackDismiss}
      />
    );
  }
  return null;

  function trackClickUpgrade() {
    const upgradeContext =
      engagementStatus === EngagementStatus.ReachedLimit
        ? 'Reached engagement limit modal'
        : 'Activated engagement limit modal';

    trackUser({
      event: upgradeEvent,
      properties: {
        context: upgradeContext,
      },
    });

    trackModalAction(ModalAction.Upgrade);
  }

  function trackClickContinue() {
    trackModalAction(ModalAction.Continue);
  }

  function trackClickGotIt() {
    trackModalAction(ModalAction.GotIt);
  }

  function trackDismiss() {
    trackModalAction(ModalAction.Dismiss);
  }

  function trackModalAction(action: ModalAction) {
    trackUser({
      event: 'Clicked engagement limit status modal action',
      properties: {
        context: trackingContext,
        action,
        time_to_action_seconds: (Date.now() - modalOpened) / 1000,
        engagement_status: EngagementStatusNames[engagementStatus],
        engaged_participants: progress,
      },
    });
  }
}
