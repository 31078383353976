import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const themeVariants = cva(
  'items-center rounded-3xl z-notification w-full bg block overflow-auto p-6',
  {
    variants: {
      theme: {
        light: 'bg text',
        dark: 'bg-primary text-on-primary',
        brand: 'bg-brand text-on-brand',
        negative: 'bg-negative text-on-negative',
        positive: 'bg-positive on-positive',
        notice: 'bg-notice text-on-notice',
        info: 'bg-info text-on-info',
        neutral: 'bg-neutral text-on-neutral',
      },
    },
    defaultVariants: { theme: 'brand' },
  },
);

export type BannerThemeVariantProps = VariantProps<typeof themeVariants>;
