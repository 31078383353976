'use client';
import {
  ENGAGEMENT_COPY,
  useEngagementLimits,
  EngagementStatus,
} from '@mentimeter/paywalls-data-hooks';
import type { TFunction } from 'react-i18next';
import { Trans, useTranslation } from 'react-i18next';
import type { PlanT } from '@mentimeter/plans-content';
import { getPlansPreviewConfig } from '@mentimeter/plans-content';
import {
  HOST,
  fullMentimeterUrl,
  usePathname,
} from '@mentimeter/next-navigation';
import type { UserT } from '@mentimeter/user';
import { userPolicies, useUser, getIsPayingUser } from '@mentimeter/user';
import {
  useMonthlySplit,
  PlansPreviewGrid,
  usePrices,
} from '@mentimeter/plans-ui';
import { useState } from 'react';
import {
  contextFromPathname,
  TrackingContext,
  type SubscriptionsResponseT,
} from '@mentimeter/http-clients';
import Cookies from '@mentimeter/cookies';
import { useOnce } from '@mentimeter/react-hooks';
import { DEFAULT_PRICE, useSubscriptions } from '@mentimeter/billing';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useExperiment, useSplitData } from '@mentimeter/splitio';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Link } from '@mentimeter/ragnar-ui/link';
import { PillSelect } from '@mentimeter/ragnar-ui/pill-select';
import { Text } from '@mentimeter/ragnar-ui/text';
import {
  ModalRoot,
  ModalContainer,
  ModalHeader,
} from '@mentimeter/ragnar-ui/modal';
import { Button } from '@mentimeter/ragnar-ui/button';
import { ScreenReaderOnly } from '@mentimeter/ragnar-ui/screenreader-only';

const MODAL_DISMISSED_KEY = 'ENGAGEMENT_LIMIT_MODAL_DISMISSED';

export const EngagementLimitPlansPreviewModal = ({
  trackingContext: context,
}: {
  trackingContext?: TrackingContext;
}) => {
  const pathname = usePathname();
  const trackingContext = context ?? contextFromPathname(pathname);
  const engagementLimits = useEngagementLimits();
  const hasDismissedBefore = Cookies.get(MODAL_DISMISSED_KEY) === 'true';
  const { user } = useUser();
  const { subscriptions } = useSubscriptions();
  const isInPaidWorkspace = getIsPayingUser(user?.features);
  const { isInExperiment: isInNewLimitModalExperiment } = useExperiment(
    'PUG_New_Limit_Experience',
    ['modal'],
  );

  const [dismissed, setDismissed] = useState(hasDismissedBefore);

  const show =
    !dismissed &&
    engagementLimits?.engagementStatus === EngagementStatus.ReachedLimit &&
    !isInPaidWorkspace;

  useOnce(show, () => {
    trackUser({
      event: 'Viewed engagement limits plans modal',
      properties: {
        context: trackingContext,
      },
    });
  });

  if (!engagementLimits || !user || isInNewLimitModalExperiment) return null;

  return (
    <ModalRoot
      open={show}
      onOpenChange={(open: boolean) => {
        if (!open) {
          onDismiss();
        }
      }}
    >
      <ModalContainer
        data-testid="engagement-limit-modal"
        width="max-content"
        p={4}
        alignItems="center"
        overflow="auto"
      >
        <ScreenReaderOnly>
          <ModalHeader />
        </ScreenReaderOnly>
        <EngagementLimitModalContent
          user={user}
          onDismiss={onDismiss}
          subscriptions={subscriptions}
        />
      </ModalContainer>
    </ModalRoot>
  );

  function onDismiss() {
    Cookies.set({
      type: 'functional',
      name: MODAL_DISMISSED_KEY,
      value: 'true',
      options: { expires: engagementLimits?.engagementData.resetsAt ?? 30 }, // days
    });
    setDismissed(true);
  }
};

const useEngagementLimitReachedPlansPreviewConfig = ({
  user,
  subscriptions,
  isEducation,
  monthlyToggled,
  onContinueWithFree,
}: {
  user: UserT;
  subscriptions: SubscriptionsResponseT | undefined;
  isEducation: boolean;
  monthlyToggled: boolean;
  onContinueWithFree: () => void;
}): Array<PlanT> => {
  const { t } = useTranslation(['plansContentPackage']);
  const { isInMonthlyTreatment } = useMonthlySplit();
  const engagementLimits = useEngagementLimits();
  const { data: workspace } = useWorkspace();
  const { country } = useSplitData();
  const isFreeUser = user?.isFreeUser ?? false;

  const {
    prices: dynamicPrices,
    isLoading: isPriceLoading,
    error: priceError,
  } = usePrices();

  const showDynamicPrices = !priceError && !isPriceLoading && !!dynamicPrices;

  const plansPreviewConfig = getPlansPreviewConfig({
    isEducation,
    isFreeUser,
    freePlanAction: {
      href: '#',
      children: t('plans.continue_with_free'),
      disabled: false,
      onClick: onContinueWithFree,
    },
    userPolicies: userPolicies(subscriptions),
    monthlyToggled,
    highlightedPlan: monthlyToggled ? 'basic' : 'free',
    showRecommendedBadge: monthlyToggled,
    isInMonthlyTreatment,
    isOnboarding: false,
    prices: showDynamicPrices ? dynamicPrices : DEFAULT_PRICE,
    isPriceLoading,
    userEngagementLimitProgress:
      engagementLimits?.engagementData.progress ?? undefined,
    host: HOST,
    // @ts-expect-error-auto TS(2322) FIXME: Type 'FC<LinkT>' is not assignable to type 'Compon... Remove this comment to see the full error message
    Link,
    trackPlan,
    t,
    country,
  });

  return plansPreviewConfig.plans;

  function trackPlan(plan: string) {
    trackUserInPlansModal(plan);
  }

  function trackUserInPlansModal(plan: string) {
    trackUser({
      event: 'Clicked buy plan',
      properties: {
        context: TrackingContext.EngagementLimitsPlansModal,
        plan,
        'active team members': workspace?.memberStats?.active || 0,
        'current plan': user?.features.type ?? 'registered',
        'engaged participants': engagementLimits?.engagementData.progress,
      },
    });
  }
};

const EngagementLimitModalContent = ({
  user,
  subscriptions,
  onDismiss,
}: {
  user: UserT;
  subscriptions: SubscriptionsResponseT | undefined;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation(['plansContentPackage']);
  const { isInMonthlyTreatment } = useMonthlySplit();
  const [monthlyToggled, setMonthlyToggled] = useState(false);

  const toggleFunction = () => {
    setMonthlyToggled((value) => !value);
  };

  const plans = useEngagementLimitReachedPlansPreviewConfig({
    isEducation: false,
    monthlyToggled,
    onContinueWithFree: onDismiss,
    user,
    subscriptions,
  });

  return (
    <Box display="flex" alignItems="center" px={[1, 4]} py={[1, 2]}>
      <Text
        fontSize={[5, null, null, 7]}
        color="text"
        fontWeight="semiBold"
        fontFamily="heading"
        lineHeight="heading"
        textAlign="center"
      >
        {ENGAGEMENT_COPY.plans_modal.limit_reached}
      </Text>
      <Text as="p" fontSize={[3, null, null, 4]} mt={[1, 1]} textAlign="center">
        Upgrade to keep engaging your audience
      </Text>
      <Text mt={3} textAlign="center">
        <EducationalCTA t={t} />
      </Text>
      {isInMonthlyTreatment && (
        <Box width="100%" alignItems="center" mt={4}>
          <PillSelect
            width="220px"
            onSelect={toggleFunction}
            items={[
              {
                label: t('plans.pay_annually'),
                value: 'yearly',
              },
              {
                label: t('plans.pay_monthly'),
                value: 'monthly',
              },
            ]}
          />
        </Box>
      )}
      <Box mt={4}>
        <PlansPreviewGrid plans={plans} />
      </Box>
      <Button
        mt={[2, 2, 4]}
        data-testid="compare-plans-link"
        href={fullMentimeterUrl('/plans#compare')}
        width="20%"
        variant="subtle"
        style={{ minWidth: 'fit-content' }}
      >
        {t('plans.compare_plans')}
      </Button>
    </Box>
  );
};

function EducationalCTA({ t }: { t: TFunction<'common'> }) {
  return (
    <Trans
      t={t}
      i18nKey="plansContentPackage:plans.teacher_or_student"
      components={{
        Bold: <Bold />,
        Link: <Link href="/plans/education" />,
      }}
    />
  );
}

function Bold({ children }: { children?: React.ReactNode }) {
  return <Text fontWeight="bold">{children}</Text>;
}
