'use client';
import dynamic from 'next/dynamic';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';

export const DesktopMenu = dynamic(
  () =>
    import('features/shell/components/side-menu/main-menu').then(
      (m) => m.MainMenu,
    ),
  { ssr: false, loading: DesktopMenuFallback },
);

export function DesktopMenuFallback() {
  return (
    <Box width="100%" height="100%" bg="bg" p="space8">
      <Skeleton mb="space8" variant="rectangle" height="40px" />
      <Skeleton mb="space4" variant="rectangle" height="22px" />
      <Skeleton mb="space4" variant="rectangle" height="22px" />
      <Skeleton mb="space4" variant="rectangle" height="22px" />
      <Skeleton mb="space4" variant="rectangle" height="22px" />
      <Skeleton mb="space4" variant="rectangle" height="22px" />
    </Box>
  );
}
