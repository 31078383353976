import { useState } from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { Text } from '@mentimeter/ragnar-ui/text';
import { LimitModalProgressBar } from '../progress-bar/LimitModalProgressBar';
import { useHasSeenBefore } from './useHasSeenBefore';

export function ActivatedLimitModal({
  progress,
  limit,
  resetsAt,
  trackClickUpgrade,
  trackClickGotIt,
  trackDismiss,
}: {
  progress: number;
  limit: number;
  resetsAt: Date;
  trackClickUpgrade(): void;
  trackClickGotIt(): void;
  trackDismiss(): void;
}) {
  const [open, setOpen] = useState(true);

  const { hasSeenBefore } = useHasSeenBefore(
    `activated-limit-modal:${progress}`,
  );
  if (hasSeenBefore) return null;

  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      <ModalContainer>
        <ModalHeader />
        <ModalBody
          pt="space6"
          pl="space12"
          pr="space12"
          pb="space12"
          alignItems="center"
        >
          <Text fontSize="175" textAlign="center" color="text">
            You can still present this month
          </Text>
          <Text mt="space4" color="text" textAlign="center">
            On free, you can present to 50 participants each month. If more
            people join a live presentation and this limit is exceeded, you
            won’t be interrupted.
          </Text>
          <LimitModalProgressBar
            limit={limit}
            progress={progress}
            resetsAt={resetsAt}
          />
        </ModalBody>
        <ModalFooter gap={0}>
          <Button
            variant="secondary"
            href="/plans"
            target="_blank"
            mr="space1.5"
            onClick={trackClickUpgrade}
          >
            Upgrade for unlimited participants
          </Button>
          <Button variant="primary" onClick={onClickGotIt}>
            Got it
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );

  function onOpenChange(newOpen: boolean) {
    setOpen(newOpen);

    if (!newOpen) {
      trackDismiss();
    }
  }

  function onClickGotIt() {
    setOpen(false);
    trackClickGotIt();
  }
}
