import { getRoleDisplayName } from '@mentimeter/workspace';
import React, { type JSX } from 'react';
import { UserDowngradeIcon, UserUpgradeIcon } from '@mentimeter/ragnar-visuals';
import {
  WorkspaceRoleEnum,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import { Banner } from '@mentimeter/ragnar-ui/banner';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Text } from '@mentimeter/ragnar-ui/text';

const HREF =
  'https://help.mentimeter.com/en/articles/8556512-workspace-roles-in-mentimeter';

const LinkWithHref = (): JSX.Element => {
  return (
    <Link color="inherit" href={HREF}>
      Learn more
    </Link>
  );
};

const titleCopy = ({
  previousRole,
  currentRole,
}: {
  previousRole: WorkspaceRole;
  currentRole: WorkspaceRole;
}): JSX.Element | undefined => {
  const UPGRADE_TEXT = (
    <Box flexDirection="row" alignItems="center">
      {UserUpgradeIcon({ size: 2 })}
      <Text color="inherit" pl="space1">
        Your role has been upgraded to a full{' '}
        <Text color="inherit" fontWeight="semiBold">
          {getRoleDisplayName(currentRole)}
        </Text>{' '}
        by an admin. You can now create and present without any limitations.{' '}
        <LinkWithHref />
      </Text>
    </Box>
  );
  const DOWNGRADE_TEXT = (
    <Box flexDirection="row" alignItems="center">
      {UserDowngradeIcon({ size: 2 })}
      <Text color="inherit" pl="space1">
        Your role has been downgraded to{' '}
        <Text color="inherit" fontWeight="semiBold">
          {getRoleDisplayName(currentRole)}
        </Text>{' '}
        by an admin. You can still create and present, but with limitations.{' '}
        <LinkWithHref />
      </Text>
    </Box>
  );

  if (
    previousRole === WorkspaceRoleEnum.MEMBER_LITE &&
    currentRole === WorkspaceRoleEnum.USER
  ) {
    return UPGRADE_TEXT;
  }
  if (currentRole === WorkspaceRoleEnum.MEMBER_LITE) {
    return DOWNGRADE_TEXT;
  }
  return undefined;
};

export const RoleChangeBanner = ({
  previousRole,
  currentRole,
  roleChanged,
  handleRoleChangeDismiss,
}: {
  previousRole: WorkspaceRole | undefined;
  currentRole: WorkspaceRole | undefined;
  roleChanged: boolean | undefined;
  handleRoleChangeDismiss: (() => Promise<void>) | null;
}) => {
  const [showBanner, setShowBanner] = React.useState(roleChanged);

  const bannerCopy = titleCopy({
    previousRole,
    currentRole,
  });

  if (!roleChanged || !showBanner || !bannerCopy) {
    return null;
  }

  return (
    <Banner
      data-testid="role-change-banner"
      onDismiss={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        handleRoleChangeDismiss && handleRoleChangeDismiss();
        setShowBanner(false);
      }}
      theme="light"
      bg="borderInfoWeakest"
      mb="space4"
      // zIndex override is place this banner below the modal
      zIndex={9997}
    >
      {bannerCopy}
    </Banner>
  );
};
