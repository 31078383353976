import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { Box, type BoxT } from '../box';
import { Text } from '../text';
import { Link, type LinkT } from '../link';
import { Clickable } from '../clickable';
import { themeVariants, type BannerThemeVariantProps } from './Banner.variants';

export interface BannerT extends BoxT, BannerThemeVariantProps {
  children?: React.ReactNode;
  link?: LinkT;
  title?: string | undefined;
  onDismiss?: undefined | (() => void);
}

export const Banner = ({
  title,
  link,
  onDismiss,
  theme,
  children,
  className,
  ...rest
}: BannerT) => (
  <Box
    className={clsx(
      themeVariants({ theme }),
      onDismiss ? 'pr-14' : 'pr-6',
      className,
    )}
    {...rest}
  >
    {onDismiss && (
      <Clickable
        onClick={onDismiss}
        aria-label="Close Banner"
        type="button"
        className="absolute top-0 right-0 p-4 z-[1] text-inherit"
      >
        <CrossIcon aria-hidden={true} size={3} color="currentColor" />
      </Clickable>
    )}
    <Box className={`${onDismiss ? 'mx-6' : 'mx-0'} items-center`}>
      {title && (
        <Text className="text-center font-normal text-inherit text-87.5 md:text-100">
          {title}
          {link && (
            <Link className="ml-3 text-inherit text-size-inherit" {...link} />
          )}
        </Text>
      )}
      {children}
    </Box>
  </Box>
);
